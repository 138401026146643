export const useStartAnimations = (callback) => {
    const globalStore = useGlobalStore();
    const { pageTransitionComplete } = storeToRefs(globalStore);
    const isMounted = ref(false);

    onMounted(() => isMounted.value = true);

    watch(
        () => pageTransitionComplete.value && isMounted.value,
        (state) => {
            if (state) {
                nextTick(() =>
                    callback()
                );
            }
        }
    );
}

