<template>
    <NuxtRouteAnnouncer />
    <CommonGlobalLoader v-show="!appLoadingComplete" />
    <div class="h-screen">
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup>
    const nuxtApp = useNuxtApp();
    const globalStore = useGlobalStore();
    const { appLoadingComplete, pageTransitionComplete } = storeToRefs(globalStore);
    nuxtApp.hook("app:suspense:resolve", () => setTimeout(() => nuxtApp.$ScrollTrigger.refresh(true), 250));
    const isDesktop = useMediaQuery("(min-width: 1181px)");

    const { data: settings } = await useAsyncGql({
        operation: "settings"
    });

    const { data: menus } = await useAsyncGql({
        operation: "menus"
    });

    const { data: instagram } = await useAsyncGql({
        operation: "instagram"
    });

    globalStore.setSettings(settings.value?.settings);
    globalStore.setMenus(menus.value);
    globalStore.setInstagramFeed(instagram.value?.instagram);

    watch(
        () => pageTransitionComplete.value,
        (state) => {
            nextTick(() => {
                useLenis(state);
                useHeader(state);
            });
        }
    );

    watch(
        () => isDesktop.value,
        (state) => {
            nextTick(() => nuxtApp.$ScrollTrigger.refresh());
            if (state) {
                nextTick(() => {
                    globalStore.setToggleMobileMenu(false);
                });
            }
        }
    );

    // nuxtApp.hook("app:beforeMount", () => console.log("app:beforeMount"));
    // nuxtApp.hook("page:finish", () => console.log("page:finish"));
    // nuxtApp.hook("page:loading:start", () => console.log("page:loading:start"));
    // nuxtApp.hook("page:loading:end", () => {});
    // nuxtApp.hook("page:transition:finish", () => console.log("page:transition:finish"));
    // nuxtApp.hook("page:start", () => setMobileMenu(false));
    // nuxtApp.hook("page:loading:end", () => {});
</script>
