import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        settings: {},
        lenis: null,
        pageTransitionBeforeComplete: false,
        pageTransitionComplete: false,
        appLoadingComplete: false,

        //metadata
        geo: {},

        // modals
        employee: {},
        dealerId: null,
        video: null,
        toggleSearchDealer: false,

        // menus 
        menus: [],
        instagramFeed: [],
        mobileMenuToggle: false,
        wizard: {
            step: 1,
            busyIndex: -1,
            show: false
        }
    }),
    actions: {
        setLenis(payload) {
            this.lenis = payload;
        },
        setAppLoadingComplete(payload) {
            this.appLoadingComplete = payload;
        },
        setPageTransitionBeforeComplete(payload) {
            this.pageTransitionBeforeComplete = payload;
        },
        setPageTransitionComplete(payload) {
            this.pageTransitionComplete = payload;
        },
        setPageWithHero(payload) {
            this.pageWithHero = payload
        },
        setSettings(payload) {
            this.settings = payload;
        },
        setEmployee(payload) {
            this.employee = payload;
        },
        setDealerId(payload) {
            this.dealerId = payload;
        },
        setVideo(payload) {
            this.video = payload
        },
        setSearchDealer(payload) {
            this.toggleSearchDealer = payload;
        },
        setMenus(payload) {
            this.menus = payload;
        },
        setToggleMobileMenu(payload) {
            this.mobileMenuToggle = payload;
        },
        setInstagramFeed(payload) {
            this.instagramFeed = payload;
        },
        setGeoData(payload) {
            this.geo = payload;
        },
        setWizard(payload) {
            this.wizard.show = payload;
        },
        setWizardStep(payload) {
            this.wizard.step = payload;
        },
        setWizardBusyIndex(payload) {
            this.wizard.busyIndex = payload;
        },
        toggleMobileMenu() {
            this.mobileMenuToggle = !this.mobileMenuToggle;
        }
    },
})