import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["article","dealer","distributorhub","documents","ForgottenPassword","home","instagram","maintenance","menus","page","product","realtedArticles","settings","type","viewer"]}
export const GqlForgottenPassword = (...params) => useGql()('ForgottenPassword', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlDealer = (...params) => useGql()('dealer', ...params)
export const GqlDistributorhub = (...params) => useGql()('distributorhub', ...params)
export const GqlDocuments = (...params) => useGql()('documents', ...params)
export const GqlHome = (...params) => useGql()('home', ...params)
export const GqlInstagram = (...params) => useGql()('instagram', ...params)
export const GqlMaintenance = (...params) => useGql()('maintenance', ...params)
export const GqlMenus = (...params) => useGql()('menus', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlProduct = (...params) => useGql()('product', ...params)
export const GqlRealtedArticles = (...params) => useGql()('realtedArticles', ...params)
export const GqlSettings = (...params) => useGql()('settings', ...params)
export const GqlType = (...params) => useGql()('type', ...params)
export const GqlViewer = (...params) => useGql()('viewer', ...params)