<template>
    <NuxtRouteAnnouncer />
    <CommonGlobalLoader v-show="!appLoadingComplete" />
    <div class="h-screen">
        <NuxtLayout>
            <ElementsError />
        </NuxtLayout>
    </div>
</template>

<script setup>
    const nuxtApp = useNuxtApp();
    const globalStore = useGlobalStore();
    const { appLoadingComplete, pageTransitionComplete } = storeToRefs(globalStore);
    nuxtApp.hook("app:suspense:resolve", () => setTimeout(() => nuxtApp.$ScrollTrigger.refresh(true), 300));
    const isDesktop = useMediaQuery("(min-width: 1181px)");

    const { data: settings } = await useAsyncGql({
        operation: "settings"
    });

    const { data: menus } = await useAsyncGql({
        operation: "menus"
    });

    globalStore.setSettings(settings.value?.settings);
    globalStore.setMenus(menus.value);

    useSeoMeta({
        title: "PUQ Press - Page not found",
        description: "Oops, This Page Could Not Be Found!"
    });

    watch(
        () => pageTransitionComplete.value,
        (state) => {
            nextTick(() => {
                useLenis(state);
                useHeader(state);
            });
        }
    );

    watch(
        () => isDesktop.value,
        (state) => {
            nextTick(() => nuxtApp.$ScrollTrigger.refresh());
            if (state) {
                nextTick(() => {
                    globalStore.setToggleMobileMenu(false);
                });
            }
        }
    );
</script>
