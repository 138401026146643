<template>
    <div class="c-global-loader" ref="$loader">
        <div class="c-circle">
            <div class="inner">
                <div class="c-logo" ref="$logo">
                    <SvgsLogo :fill_1="'var(--dark)'" />
                </div>
            </div>
        </div>
        <div class="c-percentage">
            <span class="label">Loading</span>
            <span class="percenatge">
                <span class="counter" ref="$counter">0</span>
                <span class="symbol">%</span>
            </span>
        </div>
    </div>
</template>

<script setup>
    const { $gsap: gsap } = useNuxtApp();
    const globalStore = useGlobalStore();
    const $loader = ref(null);
    const $logo = ref(null);
    const $counter = ref(null);
    const counter = { value: 0 };

    onMounted(() => {
        const rootElement = document.documentElement;

        nextTick(() => {
            gsap.to(rootElement, {
                duration: 2,
                ease: "circ.out",
                ["--circle-loader"]: "100%"
            });

            gsap.to($logo.value, {
                opacity: 1,
                duration: 1
            });

            gsap.to($logo.value, {
                webkitFilter: "blur(0px)",
                filter: "blur(0px)",

                duration: 2
            });

            gsap.to(counter, {
                value: 100,
                duration: 2,
                ease: "sine.out",
                onUpdate: function () {
                    $counter.value.innerHTML = counter.value.toFixed(1);
                },

                onComplete() {
                    globalStore.setPageTransitionBeforeComplete(true);

                    setTimeout(() => {
                        globalStore.setAppLoadingComplete(true);
                        globalStore.setPageTransitionComplete(true);
                        document.querySelector(".lenis-content").classList.remove("is-global-loading");
                    }, 200);
                }
            });
        });
    });
</script>
