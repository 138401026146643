export const useHeader = (state) => {
    const {
        $gsap: gsap,
        $ScrollTrigger: ScrollTrigger
    } = useNuxtApp();

    const header = document.querySelector('.c-header');
    const hero = document.querySelector('[data-is-hero]')
    const wHero = document.body.classList.contains('w-hero');
    const isDarker = document.body.classList.contains('darker');

    const setTheme = (theme, hero = 'false') => {
        document.body.dataset.headerTheme = theme;
        document.body.dataset.scrolledHero = hero;
    }

    if (state) {
        gsap.to(header, {
            duration: 0.35,
            delay: 0.2,
            opacity: 1,
            ease: "power4.in"
        });

        if (wHero) {
            const headerHeight = `${(header.scrollHeight / 16)}rem`;
            const mode = isDarker ? 'dark' : 'light';

            ScrollTrigger.create({
                trigger: hero,
                invalidateOnRefresh: true,
                start: () => `0% ${headerHeight}`,
                end: () => `100% ${headerHeight}`,
                onEnter: () => setTheme(mode, 'true'),
                onEnterBack: () => setTheme(mode, 'true'),
                onLeave: () => setTheme(mode, 'false'),
                onLeaveBack: () => setTheme(mode, 'false'),
            });
            setTheme(mode, 'true')
        } else {
            setTheme('dark', 'false');
        }
    } else {
        gsap.set(header, {
            duration: .2,
            ease: 'elastic.out',
            opacity: 0
        });
    }
}