import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import Draggable from 'gsap/Draggable';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrambleTextPlugin from 'gsap/ScrambleTextPlugin';
import InertiaPlugin from 'gsap/InertiaPlugin';
import SplitText from 'gsap/SplitText';

export default defineNuxtPlugin((nuxtApp) => {
    gsap.registerPlugin(
        ScrollTrigger,
        ScrollToPlugin,
        CSSPlugin,
        Draggable,
        ScrambleTextPlugin,
        InertiaPlugin,
        SplitText
    );

    gsap.config({
        force3D: true,
        nullTargetWarn: false,
        trialWarn: false
    })

    ScrollTrigger.config({
        ignoreMobileResize: false
    });

    return {
        provide: {
            gsap,
            ScrollTrigger,
            ScrollToPlugin,
            SplitText,
            CSSPlugin,
            Draggable,
            ScrambleTextPlugin,
            InertiaPlugin
        },
    }
})