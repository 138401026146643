import { gsap } from 'gsap';

export function usePageAltTransition() {
    return {
        name: 'page-transiton',
        mode: 'out-in',
        css: false,
        onBeforeEnter: () => {
            const globalStore = useGlobalStore();
            globalStore.setPageTransitionBeforeComplete(true);
        },
        onEnter: (el, done) => {
            const globalStore = useGlobalStore();
            gsap.timeline({
                paused: true,
                onComplete: () => {
                    globalStore.setPageTransitionComplete(true);
                    document.body.dataset.scrollDirection = 'init';
                    document.body.dataset.scrolled = 'false';
                    document.body.classList.remove('is-loading');
                    document.body.classList.remove("hide-pointer-events");
                    done();
                },
            })
                .to(
                    '.c-page-loader-alt .c-page-loader--screen',
                    {
                        duration: 1,
                        yPercent: -100,
                        ease: "Expo.easeInOut"
                    },
                    "<"
                )
                .to(
                    '.c-page-loader-alt .shape-round.top',
                    {
                        duration: 0.4,
                        scaleY: 1,
                        ease: "Expo.easeIn"
                    },
                    "<"
                )
                .to(
                    '.c-page-loader-alt .shape-round.top',
                    {
                        duration: 0.4,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.5"
                )
                .set(
                    '.c-page-loader-alt .c-page-loader--screen',
                    {
                        opacity: 0
                    }
                )
                .play()

        },
        onBeforeLeave: (el) => {
            const globalStore = useGlobalStore();
            globalStore.setPageTransitionBeforeComplete(false)
            document.body.classList.add('is-loading');
            document.body.classList.add("hide-pointer-events");

            gsap.set('.c-page-loader-alt .c-page-loader--screen', {
                opacity: 1,
                yPercent: 100
            })

            gsap.set('.c-page-loader-alt .shape-round.bottom', {
                scaleY: 0
            })

            gsap.set('.c-page-loader-alt .shape-round.top', {
                scaleY: 0
            })
        },
        onLeave: (el, done) => {
            const globalStore = useGlobalStore();

            gsap.timeline({
                paused: true,
                onComplete: () => {
                    globalStore.setPageTransitionComplete(false);
                    globalStore.setToggleMobileMenu(false);
                    globalStore.setWizard(false);
                    window.scrollTo({ top: 0 });
                    setTimeout(() => {
                        done();
                    }, 250)
                },
            })
                .to('.c-page-loader--screen', {
                    duration: 0.8,
                    yPercent: 0,
                    ease: "Power4.easeInOut"
                })
                .to(
                    '.c-page-loader-alt .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 1,
                        ease: "Expo.easeIn"
                    },
                    "<"
                )
                .to(
                    '.c-page-loader-alt .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
                .to(
                    '.c-page-loader-alt .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
                .to(
                    '.c-page-loader-alt .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
                .play()
        }
    }
}