<template>
    <section class="c-error">
        <div class="c-container">
            <div class="c-row">
                <div class="c-grid align-middle">
                    <div class="cell sm-12">
                        <h1 class="heading_404">
                            <span v-for="(char, index) in ['P', 'U', 'Q']" :key="index" ref="chars">{{ char }}</span>
                        </h1>
                        <div class="description_404 pst-40">Oops, This Page Could Not Be Found!</div>
                        <div class="links_404 pst-60">
                            <NuxtLink
                                :to="{
                                    name: 'index'
                                }"
                                class="btn orange-dark"
                                title="Back to home"
                                >Back to home</NuxtLink
                            >

                            <NuxtLink
                                :to="{
                                    name: 'slug',
                                    params: {
                                        slug: 'products'
                                    }
                                }"
                                class="btn orange-dark"
                                title="Check our products"
                                >Check our products</NuxtLink
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useTemplateRef } from "vue";

    const { $gsap: gsap } = useNuxtApp();
    const globalStore = useGlobalStore();
    const charsRefs = useTemplateRef("chars");

    useStartAnimations(() => {
        if (!!charsRefs.value.length) {
            const variation = ["4", "O", "4"];

            charsRefs.value.forEach((item, index) => {
                gsap.to(item, {
                    duration: 1,
                    scrambleText: {
                        text: variation[index],
                        duration: 1,
                        revealDelay: 1,
                        speed: 0.5,
                        rightToLeft: true
                    }
                });
            });
        }
    });

    onUnmounted(() => {
        globalStore.setAppLoadingComplete(false);
        globalStore.setPageTransitionComplete(false);
        clearError({});
    });
</script>
