
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93tJ3dh2iBdlMeta } from "/vercel/path1/pages/[slug].vue?macro=true";
import { default as _91slug_93dplEM1G9UmMeta } from "/vercel/path1/pages/community/[slug].vue?macro=true";
import { default as distributorLg86le0YkTMeta } from "/vercel/path1/pages/distributor.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as _91slug_93LFV5BHtl1sMeta } from "/vercel/path1/pages/products/[slug].vue?macro=true";
import { default as _91slug_93M2SusHA1syMeta } from "/vercel/path1/pages/products/type/[slug].vue?macro=true";
import { default as set_45up_45maintenanceUiz1iVg6LoMeta } from "/vercel/path1/pages/set-up-maintenance.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    meta: _91slug_93tJ3dh2iBdlMeta || {},
    component: () => import("/vercel/path1/pages/[slug].vue")
  },
  {
    name: "community-slug",
    path: "/community/:slug()",
    meta: _91slug_93dplEM1G9UmMeta || {},
    component: () => import("/vercel/path1/pages/community/[slug].vue")
  },
  {
    name: "distributor",
    path: "/distributor",
    meta: distributorLg86le0YkTMeta || {},
    component: () => import("/vercel/path1/pages/distributor.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexzhmolsBfabMeta || {},
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    meta: _91slug_93LFV5BHtl1sMeta || {},
    component: () => import("/vercel/path1/pages/products/[slug].vue")
  },
  {
    name: "products-type-slug",
    path: "/products/type/:slug()",
    meta: _91slug_93M2SusHA1syMeta || {},
    component: () => import("/vercel/path1/pages/products/type/[slug].vue")
  },
  {
    name: "set-up-maintenance",
    path: "/set-up-maintenance",
    meta: set_45up_45maintenanceUiz1iVg6LoMeta || {},
    component: () => import("/vercel/path1/pages/set-up-maintenance.vue")
  }
]