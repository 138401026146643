import Lenis from 'lenis'

export const useLenis = (state) => {
    const {
        $gsap: gsap,
        $ScrollTrigger: ScrollTrigger
    } = useNuxtApp();

    const SCROLL_THRESHOLD = 50;
    const SCROLL_THRESHOLD_TOP = 100;

    const globalStore = useGlobalStore();
    const { lenis } = storeToRefs(globalStore);
    const scrollState = useState('scroll-state', () => 0);

    if (lenis.value && state) {
        lenis.value.destroy();
        ScrollTrigger.getAll().forEach(tr => tr.kill());
    }

    if (state) {
        globalStore.setLenis(
            new Lenis({
                smoothWheel: true,
                orientation: 'vertical',
                gestureOrientation: 'vertical',
                normalizeWheel: true
            })
        );

        document.body.dataset.scrollDirection = 'init';
        document.body.dataset.scrolled = 'false';

        lenis.value.on('scroll', ScrollTrigger.update)
        gsap.ticker.add((time) => lenis.value.raf(time * 1000))
        gsap.ticker.lagSmoothing(0);
        lenis.value.scrollTo(0, { immediate: true, force: true })

        lenis.value.on('scroll', (e) => {
            const targetScroll = e.targetScroll;

            if (Math.abs(scrollState.value - targetScroll) >= SCROLL_THRESHOLD) {
                document.body.dataset.scrollDirection = targetScroll > scrollState.value ? 'down' : 'up';
                document.body.dataset.scrolled = targetScroll >= SCROLL_THRESHOLD_TOP ? 'true' : 'false';
                scrollState.value = targetScroll;
            }
        })
    }
};