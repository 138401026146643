export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    article: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query article($slug: [String]) {
    article: entry(section: "community", slug: $slug) {
        id
        title
        slug
        ... on articlePost_Entry {
            typeHandle
            sectionHandle
            postDate @formatDateTime(format: "d.m.Y")
            articleCategory {
                id
                title
                slug
                groupHandle
            }
            articlePreview {
                id
                title
                kind
                alt
                thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
            }
            postElements {
                ... on elementText_Entry {
                    id
                    typeHandle
                    text
                }
                ... on elementGallery_Entry {
                    id
                    typeHandle
                    columns
                    gallery {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementMedia_Entry {
                    id
                    typeHandle
                    alternative
                    media {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementVideo_Entry {
                    id
                    typeHandle
                    video {
                        id
                        title
                        kind
                        url
                    }
                    videoPoster {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
                ... on elementEmbed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on elementArtist_Entry {
                    id
                    title
                    typeHandle
                    artistName
                    artistAwwwards
                    artistQuote
                    artistPhoto {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 900, quality: 75, immediately: true)
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on articleTestimonial_Entry {
            typeHandle
            sectionHandle
            postDate @formatDateTime(format: "d.m.Y")
            articleCategory {
                id
                title
                slug
                groupHandle
            }
            articlePreview {
                id
                title
                kind
                alt
                thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
            }
            postElements {
                ... on elementText_Entry {
                    id
                    typeHandle
                    text
                }
                ... on elementGallery_Entry {
                    id
                    typeHandle
                    columns
                    gallery {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementMedia_Entry {
                    id
                    typeHandle
                    alternative
                    media {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementVideo_Entry {
                    id
                    typeHandle
                    video {
                        id
                        title
                        kind
                        url
                    }
                    videoPoster {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
                ... on elementEmbed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on elementArtist_Entry {
                    id
                    title
                    typeHandle
                    artistName
                    artistAwwwards
                    artistQuote
                    artistPhoto {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 900, quality: 75, immediately: true)
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'article', 'query'),
    dealer: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query dealer($id: [QueryArgument]) {
    dealer: entry(section: "dealers", id: $id) {
        id
        title
        slug
        typeHandle
        ... on dealer_Entry {
            alternative
            country
            city
            addressLine1
            addressLine2
            addressLine3
            postalCode
            latitude
            longitude
            media {
                id
                title
                webp: url @transform(format: "webp", mode: "fit", width: 1100, height: 510, quality: 80)
            }
            logo {
                id
                title
                url
            }
            description
            products {
                id
                title
                slug
                typeHandle
                ... on products_Entry {
                    sectionHandle
                }
            }
            customList {
                icon
                value
            }
            emails {
                email
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dealer', 'query'),
    distributorhub: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query distributorhub {
    distributorhub: entries(section: "distributorHub") {
        id
        title
        ... on distributorHub_Entry {
            description
            loginHeadline
            loginDescription
            loginText
            distributor {
                ... on groups_Entry {
                    id
                    title
                    chapters {
                        ... on chapter_Entry {
                            id
                            title
                            files {
                                linkText
                                linkUrl
                                isElement
                                isEmpty
                                type
                                element {
                                    uid
                                }
                            }
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'distributorhub', 'query'),
    documents: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query documents($offset: Int) {
    documents: entries(section: "products", offset: $offset, limit: 36) {
        id
        title
        ... on products_Entry {
            productMediaPreview {
                ... on productPreview_Entry {
                    id
                    color
                    productPreview {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                mode: "fit"
                                format: "webp"
                                width: 900
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                }
            }
            distributor {
                ... on groups_Entry {
                    id
                    title
                    chapters {
                        ... on chapter_Entry {
                            id
                            title
                            files {
                                linkText
                                isElement
                                isEmpty
                                target
                                element {
                                    id
                                    uid
                                    title
                                    ... on instructions_Asset {
                                        mimeType
                                        kind
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'documents', 'query'),
    ForgottenPassword: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`mutation ForgottenPassword($email: String!) {
    forgottenPassword(email: $email)
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ForgottenPassword', 'query'),
    home: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query home {
    home: entry(section: "home") {
        id
        title
        slug
        typeHandle
        ... on home_Entry {
            heroHeadline
            heroDescription
            heroLogo {
                id
                title
                url
            }
            heroImage {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 75, immediately: true)
            }
            heroVideo {
                id
                title
                kind
                url
            }
            heroWidgetDealer
            heroWidgetNewProduct
            introLogo {
                id
                title
                url
            }
            introLabel
            introText
            introDescription
            introGallery {
                id
                title
                webp: url @transform(mode: "fit", format: "webp", width: 1200, height: 1600, quality: 75, immediately: true)
            }
            promotionHeadline
            promotionLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            promotions {
                ... on promotion_Entry {
                    id
                    title
                    headline
                    description
                    promotionType
                    media {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 900
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                    video {
                        id
                        title
                        url
                    }
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
            boxesHeadline
            boxes {
                ... on box_Entry {
                    id
                    headline
                    description
                    media {
                        id
                        title
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1200
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                }
            }
            compareLeftHeading
            compareRightHeading
            compareLeftDescription
            compareRightDescription
            compareLeftVideo {
                id
                title
                url
            }
            compareRightVideo {
                id
                title
                url
            }
            compareLeftMedia {
                id
                title
                webp: url @transform(mode: "crop", format: "webp", width: 1600, quality: 75, immediately: true)
            }
            compareRightMedia {
                id
                title
                webp: url @transform(mode: "crop", format: "webp", width: 1600, quality: 75, immediately: true)
            }
            reviewsHeadline
            reviews {
                ... on review_Entry {
                    id
                    title
                    reviewName
                    reviewFunction
                    reviewQuote
                    media {
                        id
                        title
                        kind
                        webp: url @transform(mode: "crop", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
            }
            communityHeadline
            communityLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            articles {
                id
                title
                slug
                ... on articlePost_Entry {
                    articlePreview {
                        id
                        title
                        thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
                    }
                    articleCategory {
                        id
                        title
                        slug
                    }
                    featured
                }
                ... on articleTestimonial_Entry {
                    articlePreview {
                        id
                        title
                        thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
                    }
                    articleCategory {
                        id
                        title
                        slug
                    }
                    featured
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'home', 'query'),
    instagram: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query instagram {
    instagram {
        username
        biography
        profilePictureUrl
        website
        followersCount
        followsCount
        hashtags
        posts {
            id
            timestamp
            permalink
            mediaType
            isReel
            mediaUrl
            thumbnailUrl
            sizes {
                small {
                    height
                    width
                    mediaUrl
                }
                medium {
                    height
                    width
                    mediaUrl
                }
                large {
                    height
                    width
                    mediaUrl
                }
                full {
                    height
                    width
                    mediaUrl
                }
            }
            caption
            prunedCaption
            hashtags
            hashtag
            mentions
            children {
                id
                mediaType
                mediaUrl
                sizes {
                    small {
                        height
                        width
                        mediaUrl
                    }
                    medium {
                        height
                        width
                        mediaUrl
                    }
                    large {
                        height
                        width
                        mediaUrl
                    }
                    full {
                        height
                        width
                        mediaUrl
                    }
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'instagram', 'query'),
    maintenance: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query maintenance {
    maintenance: entry(section: "setUpMaintenance") {
        id
        title
        slug
        ... on setUpMaintenance_Entry {
            headline
            products {
                id
                title
                slug
                sectionHandle
                ... on products_Entry {
                    alternative
                    productMediaPreview {
                        ... on productPreview_Entry {
                            id
                            color
                            productPreview {
                                id
                                title
                                url
                                webp: url
                                    @transform(
                                        mode: "fit"
                                        format: "webp"
                                        width: 900
                                        height: 1200
                                        quality: 75
                                        immediately: true
                                    )
                            }
                        }
                    }
                    faq {
                        ... on faqGroup_Entry {
                            id
                            title
                            faqItems {
                                ... on faqItem_Entry {
                                    id
                                    title
                                    text
                                }
                            }
                        }
                    }
                    instructions {
                        ... on group_Entry {
                            id
                            title
                            instructionItems {
                                ... on instructionItem_Entry {
                                    id
                                    media {
                                        id
                                        title
                                        webp: url
                                            @transform(
                                                mode: "crop"
                                                format: "webp"
                                                width: 700
                                                height: 440
                                                quality: 75
                                                immediately: true
                                            )
                                    }
                                    videoLink {
                                        linkUrl
                                        linkText
                                        isElement
                                        isEmpty
                                        newWindow
                                        type
                                        element {
                                            ... on AssetInterface {
                                                id
                                                title
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            faqHeadline
            faqDescription
            links {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            faq {
                ... on faqGroup_Entry {
                    id
                    title
                    faqItems {
                        ... on faqItem_Entry {
                            id
                            title
                            text
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'maintenance', 'query'),
    menus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query menus {
    main_menu: navigationNodes(navHandle: "mainMenu") {
        id
        title
        url
        classes
        type
        typeLabel
        newWindow
        level
        ... on mainMenu_Node {
            style
            menuGroups {
                ... on menuItem_Entry {
                    id
                    headline
                    typeHandle
                    media {
                        id
                        title
                        webp: url
                            @transform(
                                mode: "fit"
                                format: "webp"
                                width: 1400
                                height: 800
                                quality: 75
                                immediately: true
                                position: "center"
                            )
                    }
                    promotionLink {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            customAttributes
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
                ... on menuLinks_Entry {
                    id
                    typeHandle
                    headline
                    menuLinks {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            customAttributes
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                    promotionLink {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            customAttributes
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
                ... on menuMediaLinks_Entry {
                    id
                    typeHandle
                    media {
                        id
                        title
                        webp: url
                            @transform(
                                mode: "fit"
                                format: "webp"
                                width: 1000
                                height: 1400
                                quality: 75
                                position: "center"
                                immediately: true
                            )
                    }
                    menuLinks {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            customAttributes
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
                ... on menuProductGroup_Entry {
                    id
                    typeHandle
                    media {
                        id
                        title
                        webp: url
                            @transform(
                                mode: "fit"
                                format: "webp"
                                width: 1000
                                height: 600
                                quality: 80
                                position: "center"
                                immediately: true
                            )
                    }
                    groupHeadline
                    groupDescription
                    products {
                        ... on products_Entry {
                            id
                            title
                            slug
                            sectionHandle
                            menuMedia {
                                id
                                title
                                url
                            }
                            menuText
                        }
                    }
                    promotionLink {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            customAttributes
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
        }
        element {
            id
            slug
            ... on EntryInterface {
                sectionHandle
                typeHandle
            }
            ... on CategoryInterface {
                groupHandle
            }
        }
    }
    menu_footer_products: navigationNodes(navHandle: "footerProductLinks", level: 1) {
        id
        title
        url
        classes
        type
        typeLabel
        newWindow
        customAttributes {
            attribute
            value
        }
        element {
            id
            slug
            ... on EntryInterface {
                sectionHandle
                typeHandle
            }
            ... on CategoryInterface {
                groupHandle
            }
        }
    }
    menu_footer_company: navigationNodes(navHandle: "footerCompanyLinks", level: 1) {
        id
        title
        url
        classes
        type
        typeLabel
        newWindow
        customAttributes {
            attribute
            value
        }
        element {
            id
            slug
            ... on EntryInterface {
                sectionHandle
                typeHandle
            }
            ... on CategoryInterface {
                groupHandle
            }
        }
    }
    menu_footer_help: navigationNodes(navHandle: "footerHelpLinks", level: 1) {
        id
        title
        url
        classes
        type
        typeLabel
        newWindow
        customAttributes {
            attribute
            value
        }
        element {
            id
            slug
            ... on EntryInterface {
                sectionHandle
                typeHandle
            }
            ... on CategoryInterface {
                groupHandle
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'menus', 'query'),
    page: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query page($slug: [String]) {
    page: entry(section: "pages", slug: $slug) {
        id
        title
        slug
        typeHandle
        ... on aboutTemplate_Entry {
            heroHeadline
            heroDescription
            heroImage {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 75, immediately: true)
            }
            heroVideo {
                id
                title
                kind
                url
            }
            heroWidgetDealer
            introLabel
            introText
            introSlider {
                ... on introSlide_Entry {
                    media {
                        id
                        title
                        url
                        webp: url @transform(mode: "crop", format: "webp", width: 1600, quality: 80, immediately: true)
                    }
                    text
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                    typeHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
            teamTitle
            teamList {
                ... on team_Entry {
                    id
                    title
                    media {
                        id
                        title
                        webp: url @transform(mode: "fit", format: "webp", height: 750, quality: 80, immediately: true)
                    }
                    employeePosition
                    employeeDescription
                    text
                }
            }
            mediaSlider {
                ... on mediaSlide_Entry {
                    id
                    label
                    headline
                    text
                    media {
                        id
                        title
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1200
                                height: 1300
                                quality: 75
                                immediately: true
                            )
                    }
                }
            }
            communityHeadline
            communityLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            articles {
                id
                title
                slug
                ... on articlePost_Entry {
                    articlePreview {
                        id
                        title
                        thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
                    }
                    articleCategory {
                        id
                        title
                        slug
                    }
                    featured
                }
                ... on articleTestimonial_Entry {
                    articlePreview {
                        id
                        title
                        thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
                    }
                    articleCategory {
                        id
                        title
                        slug
                    }
                    featured
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on chartTemplate_Entry {
            headline
            description
            chartSpecifications {
                label
                value
            }
            bannerTitle
            bannerText
            bannerWizard
            bannerLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on contactTemplate_Entry {
            headline
            description
            address {
                id
                title
                ... on Address {
                    locality
                    countryCode
                    addressLine1
                    addressLine2
                    addressLine3
                    organization
                    postalCode
                    latitude
                    longitude
                }
            }
            serviceLinks {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            departments {
                ... on department_Entry {
                    id
                    title
                    emails {
                        email
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on defaultTemplate_Entry {
            headline
            postElements {
                ... on elementText_Entry {
                    id
                    typeHandle
                    text
                }
                ... on elementGallery_Entry {
                    id
                    typeHandle
                    columns
                    gallery {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementMedia_Entry {
                    id
                    typeHandle
                    alternative
                    media {
                        id
                        title
                        alt
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1300
                                height: 850
                                quality: 75
                                immediately: true
                            )
                    }
                }
                ... on elementVideo_Entry {
                    id
                    typeHandle
                    video {
                        id
                        title
                        kind
                        url
                    }
                    videoPoster {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
                ... on elementEmbed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on elementArtist_Entry {
                    id
                    title
                    typeHandle
                    artistName
                    artistAwwwards
                    artistQuote
                    artistPhoto {
                        id
                        title
                        kind
                        webp: url @transform(mode: "fit", format: "webp", width: 900, quality: 75, immediately: true)
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on communityTemplate_Entry {
            heroHeadline
            heroDescription
            heroImage {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 75, immediately: true)
            }
            heroVideo {
                id
                title
                kind
                url
            }
            heroWidgetDealer
            heroWidgetNewProduct
            promotionHeadline
            promotionLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            promotions {
                ... on promotion_Entry {
                    id
                    title
                    headline
                    description
                    promotionType
                    media {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 900
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                    video {
                        id
                        title
                        url
                    }
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                    typeHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on faqTemplate_Entry {
            headline
            description
            faq {
                ... on faqGroup_Entry {
                    id
                    title
                    faqItems {
                        ... on faqItem_Entry {
                            id
                            title
                            text
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on productsTemplate_Entry {
            headline
            productsTypes {
                ... on productType_Entry {
                    id
                    promotion {
                        ... on promotion_Entry {
                            id
                            title
                            headline
                            description
                            promotionType
                            media {
                                id
                                title
                                url
                                webp: url
                                    @transform(
                                        mode: "crop"
                                        format: "webp"
                                        width: 900
                                        height: 1200
                                        quality: 75
                                        immediately: true
                                    )
                            }
                            video {
                                id
                                title
                                url
                            }
                            links {
                                ... on HyperLinkInterface {
                                    linkUrl
                                    linkText
                                    isEmpty
                                    isElement
                                    target
                                    title
                                    type
                                    element {
                                        id
                                        title
                                        slug
                                        ... on EntryInterface {
                                            sectionHandle
                                            typeHandle
                                        }
                                        ... on CategoryInterface {
                                            groupHandle
                                        }
                                    }
                                }
                            }
                        }
                    }
                    productsGroup {
                        ... on productGroup_Entry {
                            links {
                                ... on HyperLinkInterface {
                                    linkUrl
                                    linkText
                                    isEmpty
                                    isElement
                                    target
                                    title
                                    type
                                    element {
                                        id
                                        title
                                        slug
                                        ... on EntryInterface {
                                            sectionHandle
                                            typeHandle
                                        }
                                        ... on CategoryInterface {
                                            groupHandle
                                        }
                                    }
                                }
                            }
                            products {
                                ... on products_Entry {
                                    id
                                    title
                                    slug
                                    productExcerpt
                                    designedFor
                                    designedBy
                                    comingSoon
                                    productMediaPreview {
                                        ... on productPreview_Entry {
                                            id
                                            color
                                            productPreview {
                                                id
                                                title
                                                url
                                                webp: url
                                                    @transform(
                                                        mode: "fit"
                                                        format: "webp"
                                                        width: 900
                                                        height: 1200
                                                        quality: 75
                                                        immediately: true
                                                    )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            bannerTitle
            bannerText
            bannerWizard
            bannerLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on whyPuqTemplate_Entry {
            heroHeadline
            heroDescription
            heroImage {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 75, immediately: true)
            }
            heroVideo {
                id
                title
                kind
                url
            }
            heroWidgetDealer
            mediaGroups {
                ... on mediaGroup_Entry {
                    id
                    mediaItems {
                        ... on mediaItem_Entry {
                            id
                            label
                            headline
                            text
                            media {
                                id
                                title
                                kind
                                webp: url
                                    @transform(
                                        mode: "crop"
                                        format: "webp"
                                        width: 1200
                                        height: 1300
                                        quality: 75
                                        immediately: true
                                    )
                            }
                        }
                    }
                }
            }
            boxesHeadline
            boxes {
                ... on box_Entry {
                    id
                    headline
                    description
                    media {
                        id
                        title
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1200
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                }
            }
            reviewsHeadline
            reviews {
                ... on review_Entry {
                    id
                    title
                    reviewName
                    reviewFunction
                    reviewQuote
                    media {
                        id
                        title
                        kind
                        webp: url @transform(mode: "crop", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
            }
            promotionHeadline
            promotionLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            promotions {
                ... on promotion_Entry {
                    id
                    title
                    headline
                    description
                    promotionType
                    media {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 900
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                    video {
                        id
                        title
                        url
                    }
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                    typeHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'page', 'query'),
    product: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query product($slug: [String]) {
    product: entry(section: "products", slug: $slug, limit: 1) {
        id
        title
        slug
        typeHandle
        ... on products_Entry {
            scrolledContent {
                ... on scrolledVideo_Entry {
                    id
                    video {
                        id
                        title
                        url
                    }
                    videoPoster {
                        id
                        title
                        thumbnail: url
                            @transform(
                                format: "webp"
                                mode: "fit"
                                width: 900
                                height: 500
                                quality: 80
                                immediately: true
                            )
                    }
                    typeHandle
                }
                ... on scrolledText_Entry {
                    id
                    subheading
                    headline
                    text
                    typeHandle
                }
            }
            alternative
            productDescription
            productNotes
            ignoreHero
            productMediaGallery {
                ... on preview_Entry {
                    id
                    color
                    productGallery {
                        id
                        title
                        url
                        kind
                        thumbnail: url
                            @transform(
                                format: "webp"
                                mode: "fit"
                                width: 300
                                height: 300
                                quality: 80
                                immediately: true
                            )
                    }
                }
            }
            product3dModel {
                id
                title
                url
            }
            productBrochure {
                id
                title
                url
                kind
            }
            productSpecifications {
                label
                values
                new
                showOnOverview
                commaSeparator
            }
            productCustomContent {
                ... on customContent_Entry {
                    id
                    title
                    description
                }
            }
            reviewsHeadline
            reviews {
                ... on review_Entry {
                    id
                    title
                    reviewName
                    reviewFunction
                    reviewQuote
                    media {
                        id
                        title
                        kind
                        webp: url @transform(mode: "crop", format: "webp", width: 1200, quality: 75, immediately: true)
                    }
                }
            }
            videoHeadline
            videoPoster {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 1440, immediately: true)
            }
            video {
                id
                title
                url
            }
            promotionHeadline
            promotionLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                        }
                    }
                }
            }
            promotions {
                ... on promotion_Entry {
                    id
                    title
                    headline
                    description
                    promotionType
                    media {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 900
                                height: 1200
                                quality: 75
                                immediately: true
                            )
                    }
                    video {
                        id
                        title
                        url
                    }
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                            }
                        }
                    }
                }
            }
            bannerTitle
            bannerText
            bannerWizard
            bannerLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'product', 'query'),
    realtedArticles: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query realtedArticles($excludeId: QueryArgument, $realtedCategories: [QueryArgument]) {
    realtedArticles: entries(
        section: "community"
        id: ["not", $excludeId]
        articleCategory: $realtedCategories
        orderBy: "postDate DESC"
        showLink: true
        limit: 3
    ) {
        id
        title
        slug
        ... on articlePost_Entry {
            typeHandle
            sectionHandle
            featured
            articleCategory {
                id
                title
                slug
                groupHandle
            }
            articlePreview {
                id
                title
                kind
                alt
                thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
            }
        }
        ... on articleTestimonial_Entry {
            typeHandle
            sectionHandle
            featured
            articleCategory {
                id
                title
                slug
                groupHandle
            }
            articlePreview {
                id
                title
                kind
                alt
                thumbnail: url @transform(format: "webp", mode: "crop", width: 1700, height: 1000, quality: 80)
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'realtedArticles', 'query'),
    settings: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query settings {
    settings: globalSets {
        ... on settings_GlobalSet {
            pinterest
            facebook
            twitter
            instagram
            copyright @parseRefs
            subscribeRecipients {
                email
            }
            communityLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            typeHandle
                            sectionHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            widgetProductImage {
                id
                title
                url
            }
            widgetProductLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            typeHandle
                            sectionHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
        }
        ... on wizard_GlobalSet {
            wizardLinks {
                ... on wizardLinksGroup_Entry {
                    headline
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                }
            }
            wizardProducts {
                ... on wizardProducts_Entry {
                    productsRecomended {
                        ... on productItem_Entry {
                            recommend
                            product {
                                ... on products_Entry {
                                    id
                                    title
                                    slug
                                    typeHandle
                                    recommend
                                    wizardExcerpt
                                    productMediaPreview {
                                        ... on productPreview_Entry {
                                            id
                                            color
                                            productPreview {
                                                id
                                                title
                                                url
                                                webp: url
                                                    @transform(
                                                        mode: "fit"
                                                        format: "webp"
                                                        width: 900
                                                        height: 1200
                                                        quality: 75
                                                        immediately: true
                                                    )
                                            }
                                        }
                                    }
                                    productSpecifications {
                                        label
                                        values
                                        new
                                        showOnOverview
                                        commaSeparator
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'settings', 'query'),
    type: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query type($slug: [String]) {
    type: category(group: "types", slug: $slug, limit: 1) {
        id
        title
        slug
        groupHandle
        ... on types_Category {
            alternative
            heroHeadline
            heroDescription
            types {
                ... on productsByType_Entry {
                    id
                    headline
                    alternative
                    description
                    links {
                        ... on HyperLinkInterface {
                            linkUrl
                            linkText
                            isEmpty
                            isElement
                            target
                            title
                            type
                            element {
                                id
                                title
                                slug
                                ... on EntryInterface {
                                    sectionHandle
                                }
                                ... on CategoryInterface {
                                    groupHandle
                                }
                            }
                        }
                    }
                    products {
                        id
                        title
                        slug
                        typeHandle
                        sectionHandle
                        ... on products_Entry {
                            designedFor
                            designedBy
                            productSpecifications {
                                label
                                values
                                new
                                showOnOverview
                                commaSeparator
                            }
                            productMediaPreview {
                                ... on productPreview_Entry {
                                    id
                                    color
                                    productPreview {
                                        id
                                        title
                                        url
                                        webp: url
                                            @transform(
                                                mode: "fit"
                                                format: "webp"
                                                width: 900
                                                height: 1200
                                                quality: 75
                                                immediately: true
                                            )
                                    }
                                }
                            }
                            combineWith {
                                ... on products_Entry {
                                    id
                                    title
                                    alternative
                                    slug
                                    typeHandle
                                    sectionHandle
                                }
                            }
                        }
                    }
                }
            }
            heroImage {
                id
                title
                kind
                webp: url @transform(mode: "crop", format: "webp", width: 2560, quality: 75, immediately: true)
            }
            heroVideo {
                id
                title
                kind
                url
            }
            heroWidgetDealer
            mediaSlider {
                ... on mediaSlide_Entry {
                    id
                    label
                    headline
                    text
                    media {
                        id
                        title
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 1200
                                height: 1300
                                quality: 75
                                immediately: true
                            )
                    }
                }
            }
            bannerTitle
            bannerText
            bannerWizard
            bannerLink {
                ... on HyperLinkInterface {
                    linkUrl
                    linkText
                    isEmpty
                    isElement
                    target
                    title
                    type
                    element {
                        id
                        title
                        slug
                        ... on EntryInterface {
                            sectionHandle
                            typeHandle
                        }
                        ... on CategoryInterface {
                            groupHandle
                        }
                    }
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'type', 'query'),
    viewer: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query viewer {
    viewer {
        id
        firstName
        lastName
        fullName
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'viewer', 'query')
  }
}