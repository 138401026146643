import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U4BUIcs89R from "/vercel/path1/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import nuxt_plugin_6wEQMY3tee from "/vercel/path1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_client_UYiXMU8ZyN from "/vercel/path1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import gsap_client_Z6XKCRtOCT from "/vercel/path1/plugins/gsap.client.js";
import googlemap_client_ylld6ug90x from "/vercel/path1/plugins/googlemap.client.js";
import select_client_QClknsXHI0 from "/vercel/path1/plugins/select.client.js";
import otp_client_orjZI5mZHF from "/vercel/path1/plugins/otp.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_U4BUIcs89R,
  nuxt_plugin_6wEQMY3tee,
  plugin_client_UYiXMU8ZyN,
  gsap_client_Z6XKCRtOCT,
  googlemap_client_ylld6ug90x,
  select_client_QClknsXHI0,
  otp_client_orjZI5mZHF
]